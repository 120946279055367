import { template as template_cd97557a12f8466dbadcf02a8906e300 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cd97557a12f8466dbadcf02a8906e300(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_4e5fd1fa72244614a6a98aa74454ff84 } from "@ember/template-compiler";
const FKText = template_4e5fd1fa72244614a6a98aa74454ff84(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_aff7c6514f3b483d86313689e8615a5a } from "@ember/template-compiler";
const WelcomeHeader = template_aff7c6514f3b483d86313689e8615a5a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
